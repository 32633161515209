import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from 'rxjs';
import { AppConstants } from '../../app-constants';
import { UserModel } from '../models/user.model';
import { AuthHttpService } from './auth-http.service';
import { Router } from '@angular/router';
import { AuthModel } from '../models/auth.model';
import { catchError, map } from 'rxjs/operators';
import { RegistrationModel } from '../models/registration.model';

export type UserType = UserModel | undefined;

type Role = 'saler' | 'admin' | 'superadmin' | 'audit';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = AppConstants.APP_AUTH;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  private rolePermissions: { [key in Role]: string[] } = {
    audit: ['uncheckedCert', 'checkedCert', 'checked-cert'],
    saler: [
      'addCert',
      'getTempFile',
      'importDataFile',
      'transferEKYC',
      'transferCTSs',
      'updateCertInfo',
      'removeFile',
      'transferCTSsAndNote',
      'updateButton',
      'syncCertInfo',
      'reset',
      'restoreCert',
      'deleteCert',
      'registerCert',
      'waitingCert',
      'grantedCert',
      'cancelRegistrationCert',
    ],
    admin: [
      'addCert',
      'getTempFile',
      'importDataFile',
      'transferEKYC',
      'transferCTSs',
      'updateCertInfo',
      'removeFile',
      'transferCTSsAndNote',
      'updateButton',
      'syncCertInfo',
      'reset',
      'restoreCert',
      'deleteCert',
      'rejectButton',
      'openCertBtn',
      'temporaryLockCertBtn',
      'revokeCertBtn',
      'approveButton',
      'rejectInfoButton',
      'openCertInfoBtn',
      'temporaryLockCertInfoBtn',
      'revokeCertInfoBtn',
      'invalidCert',
      'registerCert',
      'waitingCert',
      'grantedCert',
      'cancelRegistrationCert',
      'checked-cert',
    ],
    superadmin: [
      'addCert',
      'getTempFile',
      'importDataFile',
      'transferEKYC',
      'transferCTSs',
      'updateCertInfo',
      'removeFile',
      'transferCTSsAndNote',
      'updateButton',
      'syncCertInfo',
      'reset',
      'restoreCert',
      'deleteCert',
      'rejectButton',
      'openCertBtn',
      'temporaryLockCertBtn',
      'revokeCertBtn',
      'approveButton',
      'rejectInfoButton',
      'openCertInfoBtn',
      'temporaryLockCertInfoBtn',
      'revokeCertInfoBtn',
      'tabDanhMuc',
      'tabCoCau',
      'tabDaiLy',
      'tabThongKe',
      'packageManagement',
      'invalidCert',
      'registerCert',
      'waitingCert',
      'grantedCert',
      'cancelRegistrationCert',
      'checked-cert',
    ],
  };

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHttpService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  register(payload: RegistrationModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.signup(payload).pipe(
      map((res) => {
        this.isLoadingSubject.next(false);
        return res;
      }),
      catchError((err) => {
        return of(null);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  login(username: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(username, password).pipe(
      map((res: any) => {
        return this.setAuthFromLocalStorage(res);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.currentUserSubject.next(undefined);
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.accessToken).pipe(
      map((res: any) => {
        const user = new UserModel();
        user.setUser(res);

        if (user) {
          this.currentUserSubject.next(user);
          this.setRole(user.roles);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(username: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(username)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  confirmForgotPassword(token: string, newPassword: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .confirmForgotPassword(token, newPassword)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }
      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private currentUserRole: Role = 'audit';

  hasPermission(permission: string): boolean {
    const permissions = this.rolePermissions[this.currentUserRole];
    return permissions.includes(permission);
  }

  setRole(role: string | null) {
    if (role === 'ROLE_ADMIN') {
      this.currentUserRole = 'admin';
    } else if (role === 'ROLE_SUPER_ADMIN') {
      this.currentUserRole = 'superadmin';
    } else if (role === 'ROLE_SALE') {
      this.currentUserRole = 'saler';
    } else {
      this.currentUserRole = 'audit';
    }
  }

  getRole(): Role {
    return this.currentUserRole;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
